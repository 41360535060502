import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { provideEnvironmentNgxMask } from 'ngx-mask';

import { NotificationsBellIconModule } from '@shure/cloud/shared/notifications-bell-icon';
import { SwitchOrganizationModule } from '@shure/cloud/shared/switch-organization/feature-switch-organization';
import {
	FooterModule,
	HeaderToolbarModule,
	SidenavLayoutModule,
	StackedLayoutModule,
	MyAccountSideNavModule
} from '@shure/cloud/shared/ui/components';
import { PipesModule } from '@shure/cloud/shared/ui/pipes';

import { AppShellComponent } from './app-shell.component';

@NgModule({
	declarations: [AppShellComponent],
	imports: [
		CommonModule,
		NotificationsBellIconModule,
		HeaderToolbarModule,
		MatIconModule,
		MatListModule,
		MatDividerModule,
		MatTooltipModule,
		RouterModule,
		TranslocoModule,
		FlexLayoutModule,
		StackedLayoutModule,
		MatSnackBarModule,
		MyAccountSideNavModule,
		SidenavLayoutModule,
		SwitchOrganizationModule,
		FooterModule,
		PipesModule
	],
	providers: [provideEnvironmentNgxMask()]
})
export class AppShellModule {}
