import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { OktaCallbackComponent, OktaAuthModule, OktaAuthGuard } from '@okta/okta-angular';

import { oktaSessionGuard } from '@shure/cloud/shared/okta/data-access';
import { PermissionsGuard } from '@shure/cloud/shared/permissions/feature-permissions';

import { AppShellComponent, AppShellModule } from '../components/app-shell';
import { welcomeGuard } from '../guards/welcome.guard';
import { secondaryNavResolver } from '../resolvers/secondary-nav-resolver';

const appRoutes: Route[] = [
	{
		path: 'welcome',
		loadChildren: () =>
			import('@shure/cloud/shure-cloud/welcome-page/feature-welcome-page').then((m) => m.WelcomeModule),
		canActivate: [welcomeGuard]
	},
	{
		path: 'imprint',
		loadChildren: () => import('@shure/cloud/shure-cloud/imprint/feature-imprint').then((m) => m.ImprintModule)
	},
	{
		path: 'twitch.tv',
		loadChildren: () => import('@shure/cloud/shure-cloud/twitch/feature-twitch').then((m) => m.TwitchModule)
	},
	{
		path: 'maintenance',
		loadChildren: () =>
			import('@shure/cloud/shared/maintenance/feature-maintenance').then((m) => m.MaintenanceModule),
		canActivate: [OktaAuthGuard, oktaSessionGuard, PermissionsGuard]
	},
	{
		path: '',
		redirectTo: 'welcome',
		pathMatch: 'full'
	},
	{
		path: '',
		component: AppShellComponent,
		children: [
			{
				path: 'home',
				loadChildren: () =>
					import('@shure/cloud/shure-cloud/home-page/feature-home-page').then((m) => m.HomePageModule),
				canActivate: [OktaAuthGuard, oktaSessionGuard, PermissionsGuard],
				resolve: { secondaryNavResolver }
			},
			{
				path: 'apps',
				loadChildren: () =>
					import('@shure/cloud/shure-cloud/apps-list/feature-apps-list').then((m) => m.AppsListModule),
				canActivate: [OktaAuthGuard, oktaSessionGuard, PermissionsGuard],
				resolve: { secondaryNavResolver }
			},
			{
				path: 'account',
				loadChildren: () =>
					import('@shure/cloud/shared/account/feature-account-ignite').then((m) => m.AccountIgniteModule),
				canActivate: [OktaAuthGuard, oktaSessionGuard, PermissionsGuard],
				resolve: { secondaryNavResolver }
			},
			{
				path: 'licenses',
				loadChildren: () =>
					import('@shure/cloud/shure-cloud/license-management/feature-license-management').then(
						(m) => m.LicenseManagementModule
					),
				canActivate: [OktaAuthGuard, oktaSessionGuard, PermissionsGuard],
				data: {
					feature: 'licenses',
					title: 'cloud.shure-cloud.license-management.licenses'
				},
				resolve: { secondaryNavResolver }
			},
			{
				path: 'user-management',
				loadChildren: () =>
					import('@shure/cloud/shure-cloud/users/feature-users').then((m) => m.UserManagementModule),
				canActivate: [OktaAuthGuard, oktaSessionGuard, PermissionsGuard],
				resolve: { secondaryNavResolver }
			},
			{
				path: 'event-log',
				loadChildren: () =>
					import('@shure/cloud/shure-cloud/activity-logs/feature-activity-logs').then(
						(m) => m.ActivityLogsModule
					),
				canActivate: [OktaAuthGuard, oktaSessionGuard, PermissionsGuard],
				resolve: { secondaryNavResolver }
			},
			{
				path: 'organization-settings',
				loadChildren: () =>
					import('@shure/cloud/shure-cloud/organization-settings/feature-organization-settings').then(
						(m) => m.OrganizationSettingsModule
					),
				canActivate: [OktaAuthGuard, oktaSessionGuard, PermissionsGuard],
				data: {
					feature: 'organization-settings',
					title: 'cloud.shure-cloud.org-settings.organization-settings'
				},
				resolve: { secondaryNavResolver }
			},
			{
				path: 'notifications-list',
				loadChildren: () =>
					import('@shure/cloud/shared/notifications-list').then((m) => m.NotificationsListModule),
				canActivate: [OktaAuthGuard, oktaSessionGuard, PermissionsGuard],
				resolve: { secondaryNavResolver }
			},
			{
				path: 'notifications-preferences',
				loadChildren: () =>
					import('@shure/cloud/shared/notification-preferences/feature-notification-preferences').then(
						(m) => m.NotificationPreferencesModule
					),
				canActivate: [OktaAuthGuard, oktaSessionGuard, PermissionsGuard],
				resolve: { secondaryNavResolver }
			}
		]
	},
	{
		path: 'signin/callback',
		component: OktaCallbackComponent
	},
	{
		path: '**',
		redirectTo: 'welcome',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [
		/**
		 * In order to navigate from electron to sub routes e.g. '/room/xxx-xxx' hashing has
		 * to be enabled.
		 */
		RouterModule.forRoot(appRoutes, {
			initialNavigation: 'enabledBlocking',
			useHash: false /*,
			paramsInheritanceStrategy: 'always'*/
		}),
		OktaAuthModule,
		AppShellModule
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
