import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Observable, tap } from 'rxjs';

import { OktaInterfaceService } from '@shure/cloud/shared/okta/data-access';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
	public defaultOrgId = '';
	constructor(
		@Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
		private oktaIntfService: OktaInterfaceService
	) {
		//subscribed to getDefaultOrgId$ observable to get latest selected organization id
		this.oktaIntfService
			.getDefaultOrgId$()
			.pipe(
				tap((orgId) => {
					this.defaultOrgId = orgId;
				})
			)
			.subscribe();
	}
	public intercept(req: HttpRequest<unknown>, delegate: HttpHandler): Observable<HttpEvent<unknown>> {
		let reqHeaders = req.headers;
		if (!req.url.includes('s3')) {
			if (!req.url.includes(`${this.oktaAuth.options.issuer}`)) {
				reqHeaders = reqHeaders.set('Authorization', `Bearer ${this.oktaAuth.getAccessToken()}`);
				if (req.method !== 'DELETE') {
					reqHeaders = reqHeaders.set('Content-Type', 'application/json');
				}
			}
		} else if (req.url.includes('s3')) {
			reqHeaders.set('Content-Type', 'multipart/form-data');
		}

		const request = req.clone({ headers: reqHeaders });
		return delegate.handle(request);
	}
}
