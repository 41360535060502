import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface ApiUrlConfig {
	apiBaseUrl: string | undefined;
	rolesApiUrl?: string | undefined;
	usersApiUrl?: string | undefined;
	// wsBaseUrl?: string;
}

export const APIBASEURL_CONFIG = new InjectionToken<ApiUrlConfig>('APIBASEURL_CONFIG');

@Injectable()
export class ApiBaseurlInterceptor implements HttpInterceptor {
	constructor(@Inject(APIBASEURL_CONFIG) private apiUrlConfig: ApiUrlConfig) {}

	public intercept(req: HttpRequest<unknown>, delegate: HttpHandler): Observable<HttpEvent<unknown>> {
		if (req.url.indexOf('/api/') === 0) {
			if (req.url.includes('/roles')) {
				return delegate.handle(req.clone({ url: `${this.apiUrlConfig.rolesApiUrl}${req.url}` }));
			}
			//As we are accessing the user's api from permissions library we are passing the users api url
			if (req.url.includes('/assignRole')) {
				return delegate.handle(req.clone({ url: `${this.apiUrlConfig.usersApiUrl}${req.url}` }));
			}
			return delegate.handle(req.clone({ url: `${this.apiUrlConfig.apiBaseUrl}${req.url}` }));
		}

		return delegate.handle(req);
	}
}
