import { ChangeDetectionStrategy, Component, computed, Inject, input, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@jsverse/transloco';

import { OrganizationsStoreService } from '@shure/cloud/shared/services/organizations-store-service';
import { generateColorFromName } from '@shure/cloud/shared/ui/components';
import { CloseTextOption, SnackbarService } from '@shure/cloud/shared/ui/components';
import { ApiUsersService } from '@shure/cloud/shared/users/data-access';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';

import { SwitchOrganizationService } from './services/switch-organization.service';

@Component({
	selector: 'sh-switch-organization-main',
	templateUrl: './switch-organization-main.component.html',
	styleUrls: ['./switch-organization-main.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchOrganizationMainComponent implements OnInit {
	public contentOrientation = input<'rowLayout' | 'columnLayout'>('columnLayout');

	public accountInformationSignal = this.organizationsStore.accountInformation;

	public initials = computed(() => {
		if (this.organizationName() === '') return '';
		return (
			this.organizationName().split(' ')[0].charAt(0).toUpperCase() +
			(this.organizationName().split(' ')[1]?.charAt(0).toUpperCase() || '')
		);
	});

	public colorIndex = computed(() => {
		return generateColorFromName(this.organizationName());
	});

	public organizationName = computed(() => {
		const orgName = this.accountInformationSignal()?.organizationDetails?.organizationName ?? '';
		return orgName;
	});

	constructor(
		private switchOrganizationService: SwitchOrganizationService,
		private organizationsStore: OrganizationsStoreService,
		@Inject(APP_ENVIRONMENT) public appEnv: AppEnvironment,
		private apiUsersService: ApiUsersService,
		private readonly destroyRef: DestroyRef,
		private snackBarService: SnackbarService,
		private translocoService: TranslocoService
	) {}

	public ngOnInit(): void {
		const orgDetails = this.accountInformationSignal()?.organizationDetails;
		if (!orgDetails) {
			this.getAccountInfo();
		}
	}

	public openChangeOrganization(): void {
		this.switchOrganizationService.openChangeDialog();
	}

	/**
	 * Fetches session account information from the API.
	 * The response is processed, and if valid data is found, it updates the organization store with the fetched account info.
	 */
	public getAccountInfo(): void {
		this.apiUsersService
			.getSessionAccountInfo$Response()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (accountInfoResp) => {
					if (accountInfoResp?.body?.body) {
						this.organizationsStore.setAccountInfo(accountInfoResp.body.body);
					}
				},
				error: (error) => {
					const errorMessage = error.error;
					this.snackBarService.open(
						this.translocoService.translate(
							errorMessage?.i18nKey
								? 'cloud.shared.error-labels.' + errorMessage.i18nKey
								: errorMessage.message
						),
						CloseTextOption.Ok
					);
				}
			});
	}
}
