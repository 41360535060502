import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { APIBASEURL_CONFIG, ApiUrlConfig } from './api-baseurl.interceptor';

@NgModule({
	imports: [CommonModule]
})
export class HttpModule {
	public static forRoot(apiUrlConfig: ApiUrlConfig, interceptors: Provider[]): ModuleWithProviders<HttpModule> {
		return {
			ngModule: HttpModule,
			providers: [
				...interceptors,
				{
					provide: APIBASEURL_CONFIG,
					useValue: apiUrlConfig
				}
			]
		};
	}
}
