import { Component, OnInit, Inject, DestroyRef, HostListener, runInInjectionContext, Injector } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Meta } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { OktaAuthStateService } from '@okta/okta-angular';
import { AuthState } from '@okta/okta-auth-js';
import mixpanel from 'mixpanel-browser';
import { CookieService } from 'ngx-cookie';
import { Subject } from 'rxjs';

import { oktaSessionGuard } from '@shure/cloud/shared/okta/data-access';
import { OrganizationsStoreService } from '@shure/cloud/shared/services/organizations-store-service';
import { CommonDialogsService } from '@shure/cloud/shared/ui/components';
import { AnalyticsEvents, AnalyticsService } from '@shure/cloud/shared/utils/analytics';
import { AppEnvironment, APP_ENVIRONMENT } from '@shure/cloud/shared/utils/config';

const LOCAL_STORAGE_ITEMS = {
	DIDOMI_TOKEN: 'didomi_token',
	DIDOMI_CONSENT: 'euconsent-v2'
};

export interface MaintenanceResponse {
	maintenance: boolean;
	banner: boolean;
	startAt: string;
	endAt: string;
	message: string;
}

@Component({
	selector: 'sh-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	public title = 'shure-cloud-application';
	public displayOverlay = true;
	private appVersion!: string;
	private appEnv: AppEnvironment;
	private maintenanceData!: MaintenanceResponse | null;
	private isUserAuthenticated!: boolean | undefined;
	private destroy$: Subject<void> = new Subject<void>();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public windowObj: any = window;
	constructor(
		private analyticsService: AnalyticsService,
		private metaTagService: Meta,
		private cookieService: CookieService,
		@Inject(APP_ENVIRONMENT) appEnv: AppEnvironment,
		private organizationStore: OrganizationsStoreService,
		private authStateService: OktaAuthStateService,
		private readonly destroyRef: DestroyRef,
		private router: Router,
		public commonDialogsService: CommonDialogsService,
		private injector: Injector
	) {
		this.appEnv = appEnv;
	}

	@HostListener('window:focus', ['$event'])
	public onFocus(_event: FocusEvent): void {
		// the oktaSessionGuard must run in an injection context, which is why this
		// code isn't a simple function call.
		runInInjectionContext<void>(this.injector, () =>
			oktaSessionGuard(<ActivatedRouteSnapshot>{}, <RouterStateSnapshot>{})
		);
	}

	public ngOnInit(): void {
		if (!navigator.cookieEnabled) {
			this.commonDialogsService.openCookieDialog();
			return;
		}

		this.organizationStore
			.getMaintenance()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((val) => {
				this.maintenanceData = val;
				if (this.maintenanceData?.maintenance && !window.location.href.includes('maintenance')) {
					this.router.navigate(['maintenance']);
				} else if (
					this.maintenanceData?.maintenance === false &&
					window.location.href.includes('maintenance')
				) {
					this.router.navigate(['/']);
				}
			});
		this.organizationStore
			.getVersion()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((val: string) => {
				if (!this.appVersion) {
					this.appVersion = val;
					this.didomiEventListener();
				}
			});
		if (this.appEnv.production) {
			this.metaTagService.addTags([{ name: 'robots', content: 'index' }]);
		} else {
			this.metaTagService.addTags([{ name: 'robots', content: 'noindex, nofollow' }]);
		}
		this.authStateService.authState$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((authState: AuthState) => {
			this.isUserAuthenticated = authState.isAuthenticated;
		});
	}

	public initMixPanel(): void {
		this.analyticsService.setApplicationInfo({
			application: 'Shure Cloud',
			/* eslint-disable @typescript-eslint/naming-convention */
			application_version: this.appVersion
		});
		const isMixpanelConsent =
			this.windowObj && this.windowObj.Didomi?.getCurrentUserStatus()?.vendors?.mixpanel?.enabled;
		if (this.appEnv && this.appEnv.enableMixPanel && this.appEnv.mixpanelProjectToken) {
			mixpanel?.init(this.appEnv.mixpanelProjectToken, { ip: false });
			if (isMixpanelConsent) {
				this.analyticsService.setOptIn();
				/* eslint-disable @typescript-eslint/naming-convention */
				this.analyticsService.analyticDataCollector(AnalyticsEvents.OptInState, { opted_in: true });
				if (this.isUserAuthenticated) {
					this.analyticsService.analyticDataCollector(AnalyticsEvents.SignIn, {});
				}
			} else {
				this.analyticsService.setOptIn();
				this.analyticsService.analyticDataCollector(AnalyticsEvents.OptInState, { opted_in: false });
				this.analyticsService.setOptOut();
			}
		}
	}

	/**
	 * function to listen for the didomi accept or reject event  changes,
	 * when event changes then init mixpanel and hide the overlay.
	 */
	private didomiEventListener(): void {
		// 'didomiEventListeners' not directly available on window
		// eslint-disable-next-line
		this.windowObj.didomiEventListeners = this.windowObj.didomiEventListeners || [];
		this.windowObj.didomiEventListeners.push({
			event: 'consent.changed',
			listener: () => {
				this.initializeConsented();
			}
		});
		if (this.cookieService.get(LOCAL_STORAGE_ITEMS.DIDOMI_CONSENT)) {
			this.initializeConsented();
		}
	}

	/**
	 * function to initialize, when consent has been provided
	 */
	private initializeConsented(): void {
		this.initMixPanel();
		this.displayOverlay = false;
	}
}
