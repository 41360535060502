/* libs/cloud/shared/switch-organization/feature-switch-organization/src/lib/ui/create-organization/create-organization-dialog.component.scss */
:host #sh-create-organization-dialog-container-div #sh-create-organization-action-buttons-div {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
:host #sh-create-organization-dialog-container-div #sh-create-organization-title-div {
  margin-bottom: 10px;
}
:host #sh-create-organization-dialog-container-div #sh-create-organization-title-div {
  line-height: 24px;
}
:host #sh-create-organization-dialog-container-div #sh-create-organization-company-name-field {
  margin-bottom: 10px;
  position: relative;
}
:host #sh-create-organization-dialog-container-div #sh-create-organization-company-name-field .input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
}
:host .mat-mdc-input-element,
:host #sh-create-organization-title-div,
:host mat-label {
  color: var(--prism-color-theme-ink) !important;
}
:host mat-form-field {
  min-width: 225px;
}
:host ::ng-deep .mat-mdc-dialog-container .mdc-dialog__content {
  color: var(--prism-color-theme-ink) !important;
}
:host ::ng-deep .mat-mdc-form-field-error {
  line-height: 0.8rem;
}
::ng-deep .mat-mdc-option .mdc-list-item__primary-text {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::ng-deep .mat-mdc-option .mdc-list-item__primary-text img {
  width: 20px;
}
::ng-deep .mat-mdc-autocomplete-panel {
  background-color: var(--prism-color-theme-neutral) !important;
  box-shadow: none;
}
/*# sourceMappingURL=create-organization-dialog.component.css.map */
