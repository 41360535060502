import { Injectable } from '@angular/core';

import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';
import {
	DeviceBehaviorPlugin,
	DeviceImage,
	DeviceImageBehaviorPlugin
} from '@shure/shared/angular/device-behavior/core';

/**
 * `Ad600DeviceBehaviorPluginBase` provides an base for common feature description across applications,
 * fx. designer, cui etc.
 *
 * **Do not put app specifics into this class. If app specific setup is required create a
 * plugin for the app.**
 */
@Injectable({ providedIn: 'root' })
export class Ad600DeviceBehaviorPlugin implements DeviceBehaviorPlugin, DeviceImageBehaviorPlugin {
	public readonly models: DeviceModel[] = [DeviceModel.AD600];

	public getImage(model: DeviceModel): DeviceImage | undefined {
		switch (model) {
			case DeviceModel.AD600:
				return {
					lightsBackground: '',
					live: '',
					virtual: '',
					liveBack: '',
					virtualBack: ''
				};
			default:
				return undefined;
		}
	}
}
