import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, NgModule } from '@angular/core';
import { APP_BASE_HREF, DOCUMENT } from '@angular/common';

const COOKIE_OPTIONS = new InjectionToken('COOKIE_OPTIONS');
const COOKIE_WRITER = new InjectionToken('COOKIE_WRITER');

function isNil(obj) {
    return obj === undefined || obj === null;
}
function isPresent(obj) {
    return !isNil(obj);
}
function isString(obj) {
    return typeof obj === 'string';
}
// noinspection JSUnusedGlobalSymbols
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEmpty(value) {
    if (isNil(value)) {
        return true;
    }
    if (value === {}) {
        return true;
    }
    if (isString(value) && value.length === 0) {
        return true;
    }
    if (Array.isArray(value) && value.length === 0) {
        return true;
    }
    if (typeof value !== 'boolean' && !value) {
        return true;
    }
    // noinspection RedundantIfStatementJS
    if (typeof value === 'object' && Object.keys(value).length === 0 && value.constructor === Object) {
        return true;
    }
    return false;
}
function mergeOptions(oldOptions, newOptions) {
    if (!newOptions) {
        return oldOptions;
    }
    return {
        path: isPresent(newOptions.path) ? newOptions.path : oldOptions.path,
        domain: isPresent(newOptions.domain) ? newOptions.domain : oldOptions.domain,
        expires: isPresent(newOptions.expires) ? newOptions.expires : oldOptions.expires,
        secure: isPresent(newOptions.secure) ? newOptions.secure : oldOptions.secure,
        sameSite: isPresent(newOptions.sameSite) ? newOptions.sameSite : oldOptions.sameSite,
        httpOnly: isPresent(newOptions.httpOnly) ? newOptions.httpOnly : oldOptions.httpOnly,
        storeUnencoded: isPresent(newOptions.storeUnencoded) ? newOptions.storeUnencoded : oldOptions.storeUnencoded
    };
}
function parseCookieString(currentCookieString) {
    let lastCookies = {};
    let lastCookieString = '';
    let cookieArray;
    let cookie;
    let i;
    let index;
    let name;
    if (currentCookieString !== lastCookieString) {
        lastCookieString = currentCookieString;
        cookieArray = lastCookieString.split(';');
        lastCookies = {};
        for (i = 0; i < cookieArray.length; i++) {
            cookie = cookieArray[i];
            index = cookie.indexOf('=');
            if (index > 0) { // ignore nameless cookies
                name = safeDecodeURIComponent((cookie.substring(0, index)).trim());
                // the first value that is seen for a cookie is the most
                // specific one.  values for the same cookie name that
                // follow are for less specific paths.
                if (isNil((lastCookies)[name])) {
                    lastCookies[name] = safeDecodeURIComponent((cookie.substring(index + 1)).trim());
                }
            }
        }
    }
    return lastCookies;
}
function buildCookieString(name, value, options) {
    let expires = options?.expires;
    let val;
    if (isNil(value)) {
        expires = 'Thu, 01 Jan 1970 00:00:00 GMT';
        val = '';
    }
    else {
        val = value;
    }
    if (isString(expires)) {
        expires = new Date(expires);
    }
    const cookieValue = options?.storeUnencoded ? value : encodeURIComponent(val);
    let str = encodeURIComponent(name) + '=' + cookieValue;
    str += options?.path ? ';path=' + options.path : '';
    str += options?.domain ? ';domain=' + options.domain : '';
    str += expires ? ';expires=' + expires.toUTCString() : '';
    str += options?.sameSite ? '; SameSite=' + options.sameSite : '';
    str += options?.secure ? ';secure' : '';
    str += options?.httpOnly ? '; HttpOnly' : '';
    // per http://www.ietf.org/rfc/rfc2109.txt browser must allow at minimum:
    // - 300 cookies
    // - 20 cookies per unique domain
    // - 4096 bytes per cookie
    const cookieLength = str.length + 1;
    if (cookieLength > 4096) {
        console.log('Cookie \'' + name + '\' possibly not set or overflowed because it was too large (' + cookieLength + ' > 4096 bytes)!');
    }
    return str;
}
function safeDecodeURIComponent(str) {
    try {
        return decodeURIComponent(str);
    }
    catch (e) {
        return str;
    }
}

class CookieOptionsProvider {
    constructor(options = {}, injector) {
        this.injector = injector;
        this.defaultOptions = {
            path: this.injector.get(APP_BASE_HREF, '/'),
            domain: undefined,
            expires: undefined,
            secure: false,
            httpOnly: false
        };
        this.options = mergeOptions(this.defaultOptions, options);
    }
}
CookieOptionsProvider.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "14.0.4", ngImport: i0, type: CookieOptionsProvider, deps: [{ token: COOKIE_OPTIONS }, { token: i0.Injector }], target: i0.ɵɵFactoryTarget.Injectable });
CookieOptionsProvider.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "14.0.4", ngImport: i0, type: CookieOptionsProvider });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "14.0.4", ngImport: i0, type: CookieOptionsProvider, decorators: [{
            type: Injectable
        }], ctorParameters: function () { return [{ type: undefined, decorators: [{
                    type: Inject,
                    args: [COOKIE_OPTIONS]
                }] }, { type: i0.Injector }]; } });

class CookieService {
    constructor(document, optionsProvider, cookieWriterService) {
        this.document = document;
        this.optionsProvider = optionsProvider;
        this.cookieWriterService = cookieWriterService;
        this.options = this.optionsProvider.options;
    }
    /**
     * @description
     * Returns if the given cookie key exists or not.
     *
     * @param key Id to use for lookup.
     * @returns true if key exists, otherwise false.
     */
    hasKey(key) {
        const value = this.get(key);
        return isPresent(value);
    }
    /**
     * @description
     * Returns the value of given cookie key.
     *
     * @param key Id to use for lookup.
     * @returns Raw cookie value.
     */
    get(key) {
        return this.getAll()?.[key];
    }
    /**
     * @description
     * Returns the deserialized value of given cookie key.
     *
     * @param key Id to use for lookup.
     * @returns Deserialized cookie value.
     */
    getObject(key) {
        const value = this.get(key);
        if (isNil(value)) {
            return undefined;
        }
        else if (value === '') {
            return {};
        }
        return JSON.parse(value);
    }
    /**
     * @description
     * Returns a key value object with all the cookies.
     *
     * @returns All cookies
     */
    getAll() {
        const cookieString = this.cookieWriterService.readAllAsString();
        return parseCookieString(cookieString);
    }
    /**
     * @description
     * Sets a value for given cookie key.
     *
     * @param key Id for the `value`.
     * @param value Raw value to be stored.
     * @param options (Optional) Options object.
     */
    put(key, value, options) {
        const opts = mergeOptions(this.options, options);
        this.cookieWriterService.write(key, value, opts);
    }
    /**
     * @description
     * Serializes and sets a value for given cookie key.
     *
     * @param key Id for the `value`.
     * @param value Value to be stored.
     * @param options (Optional) Options object.
     */
    putObject(key, value, options) {
        this.put(key, JSON.stringify(value), options);
    }
    /**
     * @description
     * Remove given cookie.
     *
     * @param key Id of the key-value pair to delete.
     * @param options (Optional) Options object.
     */
    remove(key, options) {
        this.put(key, undefined, options);
    }
    /**
     * @description
     * Remove all cookies.
     */
    removeAll(options) {
        const cookies = this.getAll();
        Object.keys(cookies).forEach(key => this.remove(key, options));
    }
}
CookieService.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "14.0.4", ngImport: i0, type: CookieService, deps: [{ token: DOCUMENT }, { token: CookieOptionsProvider }, { token: COOKIE_WRITER }], target: i0.ɵɵFactoryTarget.Injectable });
CookieService.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "14.0.4", ngImport: i0, type: CookieService });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "14.0.4", ngImport: i0, type: CookieService, decorators: [{
            type: Injectable
        }], ctorParameters: function () { return [{ type: undefined, decorators: [{
                    type: Inject,
                    args: [DOCUMENT]
                }] }, { type: CookieOptionsProvider }, { type: undefined, decorators: [{
                    type: Inject,
                    args: [COOKIE_WRITER]
                }] }]; } });

class CookieWriterService {
    constructor(document) {
        this.document = document;
    }
    readAllAsString() {
        return this.document.cookie || '';
    }
    write(name, value, options) {
        this.document.cookie = buildCookieString(name, value, options);
    }
}
CookieWriterService.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "14.0.4", ngImport: i0, type: CookieWriterService, deps: [{ token: DOCUMENT }], target: i0.ɵɵFactoryTarget.Injectable });
CookieWriterService.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "14.0.4", ngImport: i0, type: CookieWriterService });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "14.0.4", ngImport: i0, type: CookieWriterService, decorators: [{
            type: Injectable
        }], ctorParameters: function () { return [{ type: undefined, decorators: [{
                    type: Inject,
                    args: [DOCUMENT]
                }] }]; } });

function cookieServiceFactory(document, cookieOptionsProvider, cookieWriterService) {
    return new CookieService(document, cookieOptionsProvider, cookieWriterService);
}

class CookieModule {
    /**
     * Use this method in your root module to provide the CookieService
     */
    static withOptions(options = {}) {
        return {
            ngModule: CookieModule,
            providers: [
                { provide: COOKIE_OPTIONS, useValue: options },
                { provide: COOKIE_WRITER, useClass: CookieWriterService },
                { provide: CookieService, useFactory: cookieServiceFactory, deps: [DOCUMENT, CookieOptionsProvider, COOKIE_WRITER] }
            ]
        };
    }
    /**
     * @deprecated use `CookieModule.withOptions()` instead
     * Use this method in your root module to provide the CookieService
     */
    static forRoot(options = {}) {
        return this.withOptions(options);
    }
    /**
     * @deprecated use `CookieModule.withOptions()` instead
     * Use this method in your other (non root) modules to import the directive/pipe
     */
    static forChild(options = {}) {
        return this.withOptions(options);
    }
}
CookieModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "14.0.4", ngImport: i0, type: CookieModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
CookieModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "14.0.0", version: "14.0.4", ngImport: i0, type: CookieModule });
CookieModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "14.0.4", ngImport: i0, type: CookieModule, providers: [CookieOptionsProvider] });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "14.0.4", ngImport: i0, type: CookieModule, decorators: [{
            type: NgModule,
            args: [{
                    providers: [CookieOptionsProvider]
                }]
        }] });

/*
 * Public API Surface of ngx-cookie
 */

/**
 * Generated bundle index. Do not edit.
 */

export { COOKIE_OPTIONS, COOKIE_WRITER, CookieModule, CookieOptionsProvider, CookieService, CookieWriterService, buildCookieString, cookieServiceFactory, isEmpty, isNil, isPresent, isString, mergeOptions, parseCookieString, safeDecodeURIComponent };

