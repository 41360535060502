/* apps/cloud/shure-cloud/src/components/app-shell/app-shell.component.scss */
:host .mat-mdc-nav-list {
  margin: 0 8px;
}
:host .mat-mdc-nav-list .mat-mdc-list-item {
  height: 72px !important;
  display: flex;
  width: 90px;
  padding: 8px 5.5px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
}
:host .mat-mdc-nav-list .mat-list-text {
  display: block;
  text-align: center;
  white-space: normal;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.104px;
}
:host ::ng-deep .mat-drawer {
  background-color: #000;
}
:host ::ng-deep sh-my-account,
:host ::ng-deep sh-marketing-preference {
  display: block;
  margin-top: 40px;
  margin-left: 108px;
}
:host .sh-shell-sidenav-background-color ::ng-deep .mat-drawer {
  background-color: #222222;
}
:host .sh-shell-sidenav-color ::ng-deep .mat-drawer {
  background-color: #000000;
}
:host mat-icon,
:host #sh-left-menu-licenses-white,
:host #sh-left-menu-licenses-black {
  display: inline-block;
  height: 30px !important;
  margin: 0 25px;
  vertical-align: middle;
}
:host #sh-left-menu-licenses-black {
  display: none;
}
:host #sh-left-menu-licenses-white {
  display: block;
}
:host .sh-hide-header-toolbar {
  display: none;
}
:host .sh-secondary-nav-active {
  display: flex;
  flex-direction: column;
}
:host .sh-secondary-nav-inactive {
  display: none;
}
:host .mat-menu-item-active {
  background-color: #b2ff33 !important;
}
:host .mat-menu-item-active #sh-left-menu-licenses-white {
  display: none;
}
:host .mat-menu-item-active #sh-left-menu-licenses-black {
  display: block;
}
:host .mat-menu-item-active .mat-list-text {
  color: #000;
}
:host .mat-menu-item-active .mat-icon {
  color: #000000;
}
:host .mat-mdc-list-item[disabled] {
  color: rgba(0, 0, 0, 0.38);
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
  pointer-events: none;
}
:host #sh-shell-content-div {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
  height: 100%;
}
:host #sh-shell-content-div #sh-shell-footer-content-div {
  margin-top: 15px;
}
:host #sh-shell-maintenance-banner-div {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px;
  background-color: #ffee72;
  font-weight: 600;
}
:host #sh-shell-maintenance-banner-div #sh-shell-maintenance-message-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
:host #sh-shell-maintenance-banner-div .sh-shell-maintenance-info {
  color: #000000;
}
:host mat-icon {
  cursor: pointer;
}
:host #sh-shell-right-content-div {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
@media screen and (max-width: 960px) {
  :host ::ng-deep sh-my-account,
  :host ::ng-deep sh-marketing-preference {
    margin-left: 0;
  }
}
@media screen and (max-width: 480px) {
  :host ::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (min-width: 961px) {
  :host ::ng-deep .sh-shell-account .mat-sidenav-content {
    margin-left: 210px !important;
  }
  :host ::ng-deep .sh-shell-home .mat-sidenav-content {
    margin-left: 105px !important;
  }
}
/*# sourceMappingURL=app-shell.component.css.map */
