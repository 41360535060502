import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { catchError, of, switchMap } from 'rxjs';

import { PermissionsService } from '@shure/cloud/shared/permissions/feature-permissions';
import {
	OrganizationsStoreService,
	VerificationStatus
} from '@shure/cloud/shared/services/organizations-store-service';
import { SecondaryNavList, SecondaryNavService, ToggleSecondayNavList } from '@shure/cloud/shared/ui/components';
import { APP_ENVIRONMENT } from '@shure/cloud/shared/utils/config';
import { CloudLicenseGroupApiService } from '@shure/cloud/shure-cloud/license-management/data-access';
import { ILogger } from '@shure/shared/angular/utils/logging';
enum NavListItems {
	IntellimixRoom = 'intellimix-room',
	Anx4Receiver = 'anx4-receiver',
	AdpsmTransmitter = 'adpsm-transmitter'
}

// This resolver is used to set the flag to toggle secondary navigation.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const secondaryNavResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
	const secondaryNavService = inject(SecondaryNavService);
	const permissionService = inject(PermissionsService);
	const loggerService = inject(ILogger);
	const translocoService = inject(TranslocoService);
	const organizationsStore = inject(OrganizationsStoreService);
	const apiCloudLicenseGroupService = inject(CloudLicenseGroupApiService);
	const appEnv = inject(APP_ENVIRONMENT);
	loggerService.createScopedLogger('Secondary Navigation Resolver');

	// eslint-disable-next-line dot-notation
	if (route.data?.['feature'] === 'licenses') {
		const accountInfo = organizationsStore.accountInformation();
		const verificationStatus = accountInfo.organizationDetails?.verificationStatus;
		// featureflag for VerifiedOrganization in Licenses
		if (
			appEnv.featureFlags?.verifiedOrganizationLicenses !== false &&
			(verificationStatus === VerificationStatus.NONVERIFIED ||
				verificationStatus === VerificationStatus.REQUESTED)
		) {
			secondaryNavService.setToggleSecondaryNav(ToggleSecondayNavList.Licenses);
			secondaryNavService.setSecondaryNavListItems([]);
			return secondaryNavService.setIsPermissionAvailable(true);
		} else if (permissionService.testPermission('viewLicenseManagement')) {
			secondaryNavService.setIsPermissionAvailable(true);
			// eslint-disable-next-line @typescript-eslint/naming-convention
			return apiCloudLicenseGroupService.getLicenseProductTypes$Response().pipe(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				switchMap((response: any) => {
					secondaryNavService.setToggleSecondaryNav(ToggleSecondayNavList.Licenses);
					if (response?.body?.body?.licenseTypesList.length > 0) {
						const listItems: SecondaryNavList[] = [];
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						response?.body?.body?.licenseTypesList.forEach((licenseTypes: any) => {
							listItems.push({
								feature: 'licenses',
								key: licenseTypes.messageKey,
								listItem: translocoService.translate(
									`cloud.shure-cloud.license-management.${licenseTypes.messageKey}`
								),
								title: translocoService.translate(
									`cloud.shure-cloud.license-management.${licenseTypes.messageKey}`
								),
								path: 'license-groups-list',
								id: licenseTypes.message.substring(0, 4)
							});
							secondaryNavService.setSecondaryNavListItems(
								[...listItems].filter((listItems) => {
									switch (listItems.key) {
										case NavListItems.IntellimixRoom:
											return true;
										case NavListItems.Anx4Receiver:
											return appEnv.featureFlags?.enableAnx4ProductType ? true : false;
										case NavListItems.AdpsmTransmitter:
											return appEnv.featureFlags?.enableAdpsmProductType ? true : false;
										default:
											return false;
									}
								})
							);
						});
					} else {
						secondaryNavService.setSecondaryNavListItems([]);
					}
					return of(true);
				}),
				catchError((error) => {
					loggerService.error('Secondary Navigation Resolver', 'Error', { error });
					return of();
				})
			);
		}
		secondaryNavService.setToggleSecondaryNav(ToggleSecondayNavList.Licenses);
		secondaryNavService.setSecondaryNavListItems([]);
		return secondaryNavService.setIsPermissionAvailable(false);
	}
	// eslint-disable-next-line dot-notation
	if (route.data?.['feature'] === 'organization-settings') {
		secondaryNavService.setToggleSecondaryNav(ToggleSecondayNavList.OrganizationSettings);
		secondaryNavService.setIsPermissionAvailable(true);
		const listItems: SecondaryNavList[] = [];
		listItems.push({
			feature: 'organization-settings',
			key: 'organization',
			listItem: translocoService.translate('cloud.shure-cloud.org-settings.details'),
			path: 'details',
			id: 'details',
			title: translocoService.translate('cloud.shure-cloud.org-settings.details'),
			keyInParams: false,
			icon: 'info'
		});
		secondaryNavService.setSecondaryNavListItems([...listItems]);
		return of(true);
	}
	secondaryNavService.setToggleSecondaryNav('');
	secondaryNavService.setSecondaryNavListItems([]);
	return of(true);
};
