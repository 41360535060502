import { HttpClientModule, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarConfig, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoService } from '@jsverse/transloco';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { OktaAuth, OktaAuthOptions, StorageType } from '@okta/okta-auth-js';
import {
	NgHttpCachingModule,
	NgHttpCachingConfig,
	NgHttpCachingStrategy,
	NgHttpCachingLocalStorage
} from 'ng-http-caching';
import { CookieModule } from 'ngx-cookie';

import { ApolloSysApiCloudModule } from '@shure/cloud/shared/apollo';
import { NotificationsApiModule } from '@shure/cloud/shared/data-access/notifications';
import { CloudDeviceBehaviorPluginsModule } from '@shure/cloud/shared/device-behavior';
import { FeatureRequestApiModule } from '@shure/cloud/shared/feature-request/data-access';
import { MaintenanceApiModule } from '@shure/cloud/shared/maintenance/data-access';
import { MotivSubscriptionsApiModule } from '@shure/cloud/shared/motiv-subscriptions/data-access';
import { OktaModule } from '@shure/cloud/shared/okta/data-access';
import { PermissionsApiModule } from '@shure/cloud/shared/permissions/data-access';
import { CloudLoggerSink } from '@shure/cloud/shared/services/cloud-logger-sink';
import { UsersApiModule } from '@shure/cloud/shared/users/data-access';
import { AnalyticsModule } from '@shure/cloud/shared/utils/analytics';
import { APP_ENVIRONMENT } from '@shure/cloud/shared/utils/config';
import { ApiBaseurlInterceptor, ApiUrlConfig, AuthTokenInterceptor, HttpModule } from '@shure/cloud/shared/utils/http';
import { InactivityModule } from '@shure/cloud/shared/utils/inactivity';
import { OrganizationsApiModule } from '@shure/cloud/shure-associate/organizations/data-access';
import { ActivityLogsApiModule } from '@shure/cloud/shure-cloud/activity-logs/data-access';
import { LicenseManagementApiModule } from '@shure/cloud/shure-cloud/license-management/data-access';
import {
	DevicesDataAccessModule,
	SystemService
} from '@shure/cloud/shure-cloud/license-management/data-access-graphql';
import { RolesApiModule } from '@shure/cloud/shure-cloud/roles/data-access';
import { DataAccessUserGroupsApiModule } from '@shure/cloud/shure-cloud/users/data-access';
import { translocoInitializer, TranslocoRootModule } from '@shure/shared/angular/utils/i18n';
import { ConsoleLoggerSink, LoggingModule, StaticLoggingConfigProvider } from '@shure/shared/angular/utils/logging';

import { environment } from '../environments/environment';
import { MaterialIconsModule } from '../modules/material-icons.module';
import { CloudSystemService } from '../services/system';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const appBaseURL = window.location.origin;
const oktaDomain = appBaseURL.includes('services')
	? environment.authorization.oktaDomain
	: environment.authorization.oktaDomainExt;

// When supporting changeOrganization, we must not sync storage
// and we need to use sessionStorage instead of localStorage
const syncStorage = environment.featureFlags?.changeOrganization ? false : true;
const storageTypes: StorageType[] = environment.featureFlags?.changeOrganization
	? ['sessionStorage']
	: ['localStorage'];
const oktaConfig: OktaAuthOptions = {
	issuer: 'https://' + oktaDomain + environment.authorization.authServerPath,
	clientId: environment.authorization.clientId,
	redirectUri: `${appBaseURL}/signin/callback`,
	scopes: environment.authorization.scopes,
	logoutUrl: `${appBaseURL}/signin`,
	pkce: true,
	tokenManager: {
		autoRenew: true,
		syncStorage: syncStorage
	},
	storageManager: {
		token: {
			storageTypes: storageTypes
		},
		cache: {
			storageTypes: storageTypes
		},
		transaction: {
			storageTypes: storageTypes
		}
	}
};
const oktaAuth = new OktaAuth(oktaConfig);

const apiUrlConfig: ApiUrlConfig = {
	apiBaseUrl: environment.orgs?.orgsApiUrl,
	rolesApiUrl: environment.orgs?.rolesApiUrl
};

const cacheApiUrls = ['/permissions', '/version', '/contactdetails', '/organizationIds'];

const ngHttpCachingModule: ModuleWithProviders<NgHttpCachingModule>[] = [];
const isCookieEnabled = navigator.cookieEnabled;
if (isCookieEnabled) {
	const ngHttpCachingConfig: NgHttpCachingConfig = {
		lifetime: 1000 * 360, // cache expire after 6 mins
		allowedMethod: ['GET'],
		cacheStrategy: NgHttpCachingStrategy.ALLOW_ALL,
		store: new NgHttpCachingLocalStorage(),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		isCacheable: (req: HttpRequest<any>): boolean | undefined => {
			//Caching the response of the cacheApiUrls
			for (const url of cacheApiUrls) {
				if (req.urlWithParams.indexOf(url) !== -1) {
					return true;
				}
			}
			// by returning "false" for all the other APIs
			return false;
		}
	};
	ngHttpCachingModule.push(NgHttpCachingModule.forRoot(ngHttpCachingConfig));
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		MaterialIconsModule,
		...ngHttpCachingModule,
		ReactiveFormsModule,
		FormsModule,
		AnalyticsModule,
		TranslocoRootModule.forRoot(false),
		AppRoutingModule,
		OktaModule.forRoot(oktaAuth),
		InactivityModule.forRoot(),
		NgIdleKeepaliveModule.forRoot(),
		HttpClientModule,
		CloudDeviceBehaviorPluginsModule,
		LoggingModule.forRoot(
			environment.remoteLogging ? [CloudLoggerSink] : [ConsoleLoggerSink],
			{ base: { default: environment.logLevel } },
			StaticLoggingConfigProvider
		),
		// These are imported here instead of in the feature modules to set the environment's API URLs
		LicenseManagementApiModule.forRoot({ rootUrl: environment.orgs?.licensesApiUrl }),
		MotivSubscriptionsApiModule.forRoot({ rootUrl: environment.orgs?.motivSubscriptionsApiUrl }),
		ApolloSysApiCloudModule.forRoot(),
		DevicesDataAccessModule,
		OrganizationsApiModule.forRoot({ rootUrl: environment.orgs?.orgsApiUrl }),
		UsersApiModule.forRoot({ rootUrl: environment.orgs?.usersApiUrl }),
		DataAccessUserGroupsApiModule.forRoot({ rootUrl: environment.orgs?.userGroupsApiUrl }),
		RolesApiModule.forRoot({ rootUrl: environment.orgs?.rolesApiUrl }),
		PermissionsApiModule.forRoot({ rootUrl: environment.orgs?.pemissionsApiUrl }),
		NotificationsApiModule.forRoot({ rootUrl: environment.orgs?.notificationsApiUrl }),
		// UserPreferenceApiModule.forRoot({ rootUrl: environment.orgs?.preferencesApiUrl }),
		MaintenanceApiModule.forRoot({ rootUrl: environment.orgs?.maintenanceApiUrl }),
		FeatureRequestApiModule.forRoot({ rootUrl: environment.orgs?.featureRequestApiUrl }),
		ActivityLogsApiModule.forRoot({ rootUrl: environment.orgs?.eventLogRequestApiUrl }),
		CookieModule.withOptions(),
		HttpModule.forRoot(apiUrlConfig, [
			{
				provide: HTTP_INTERCEPTORS,
				useClass: ApiBaseurlInterceptor,
				multi: true
			},
			{
				provide: HTTP_INTERCEPTORS,
				useClass: AuthTokenInterceptor,
				multi: true
			}
		])
	],
	providers: [
		{ provide: APP_ENVIRONMENT, useValue: environment },
		{ provide: APP_INITIALIZER, useFactory: translocoInitializer, deps: [TranslocoService], multi: true },
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: <MatSnackBarConfig>{
				duration: 8000,
				verticalPosition: 'bottom',
				horizontalPosition: 'center',
				panelClass: ['mat-toolbar', 'mat-primary']
			}
		},
		{
			provide: SystemService,
			useClass: CloudSystemService
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
