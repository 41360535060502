/* libs/cloud/shared/switch-organization/feature-switch-organization/src/lib/ui/change-organization-dialog/change-organization-dialog.component.scss */
:host #sh-change-organization-dialog-container-div #sh-change-organization-action-buttons-div {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
:host #sh-change-organization-dialog-container-div #sh-change-organization-title-div {
  margin-bottom: 10px;
}
:host #sh-change-organization-dialog-container-div #sh-change-organization-title-div {
  line-height: 24px;
}
:host #sh-change-organization-dialog-container-div #sh-create-organization-company-name-field {
  margin-bottom: 10px;
  position: relative;
}
:host #sh-change-organization-dialog-container-div #sh-create-organization-company-name-field .input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
}
:host #sh-change-organization-dialog-container-div #sh-change-organization-loader-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12.5rem;
}
:host #sh-change-organization-create-button-div {
  border-radius: 2rem !important;
  background-color: var(--prism-color-theme-accent-primary);
  color: var(--prism-color-theme-base) !important;
  width: 190px;
  line-height: var(--prism-typography-global-line-height-regular) !important;
}
:host .sh-change-organization-organizations-list {
  max-height: 279px;
  height: 279px;
  overflow: auto;
}
:host .sh-change-organization-organization-name-div {
  margin: 0 10px;
}
:host .sh-change-organization-organization-item {
  height: auto;
  min-height: 52px;
  cursor: pointer;
  border-bottom: 1px solid;
}
:host .sh-change-organization-organization-item.sh-change-organization-selected {
  background-color: var(--prism-color-theme-window-film-pop);
}
:host .sh-change-organization-organization-item:hover {
  background-color: var(--prism-color-theme-window-film-hover);
}
:host #sh-change-organization-search-container-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--prism-spacing-global-medium);
  align-items: center;
  padding-bottom: 22px;
}
:host #sh-change-organization-search-bar-div,
:host #sh-change-organization-create-button-div {
  flex: 1;
  min-width: calc(50% - var(--prism-spacing-global-medium));
}
:host .sh-change-organization-details-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2px;
}
:host .sh-change-organization-icon-container-div {
  min-width: 23px;
  height: 23px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--prism-color-theme-ink);
}
:host .sh-change-organization-organization-name-h4 {
  margin: 0;
  padding-bottom: 5px;
  font-size: 15px;
  white-space: normal;
  line-height: 1.375rem;
}
:host .sh-change-organization-company-name-p {
  margin: 0;
  font-size: 11px;
}
:host #sh-change-organization-no-rows-div {
  text-align: center;
  margin-top: 20px;
}
:host .search-input {
  width: 180;
  padding-right: 10px;
}
:host #sh-change-organization-title-div,
:host mat-label {
  color: var(--prism-color-theme-ink) !important;
}
:host .sh-change-organization-org-icon-div {
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
}
:host mat-form-field {
  min-width: 225px;
}
:host ::ng-deep .mat-mdc-dialog-container .mdc-dialog__content {
  color: var(--prism-color-theme-ink) !important;
}
:host ::ng-deep .mat-mdc-form-field-error {
  line-height: 0.8rem;
}
:host ::ng-deep button.sh-search-button-arrow.mdc-icon-button {
  display: none !important;
}
:host ::ng-deep #sh-change-organization-search-bar-div .sh-search-container,
:host ::ng-deep #sh-change-organization-search-bar-div .sh-search-input {
  background-color: var(--prism-color-theme-neutral) !important;
}
@media (min-width: 385px) and (max-width: 480px) {
  :host .mat-mdc-button {
    font-size: 12px !important;
    font-weight: 700;
  }
  :host .sh-change-organization-organizations-list {
    max-height: 225px !important;
    height: 225px !important;
  }
  :host #sh-change-organization-search-container-div,
  :host #sh-change-organization-create-button-div {
    min-width: 100%;
  }
}
@media (min-width: 360px) and (max-width: 384px) {
  :host .mat-mdc-button {
    font-size: 10.5px !important;
    font-weight: 700;
  }
  :host .sh-change-organization-organizations-list {
    max-height: 225px !important;
    height: 225px !important;
  }
  :host #sh-change-organization-search-container-div,
  :host #sh-change-organization-create-button-div {
    min-width: 100%;
  }
}
@media (min-width: 667px) and (max-width: 932px) and (orientation: landscape) {
  :host .sh-change-organization-organizations-list {
    max-height: 48px !important;
    height: 48px !important;
  }
}
/*# sourceMappingURL=change-organization-dialog.component.css.map */
