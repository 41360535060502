/* libs/cloud/shared/switch-organization/feature-switch-organization/src/lib/switch-organization-main.component.scss */
:host .sh-switch-org-container.rowLayout {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 0 14px 8px 14px;
  justify-content: flex-start;
  align-items: center;
}
:host #sh-switch-org-default-org-char-div {
  border: 1px solid var(--prism-color-theme-ink);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  color: var(--prism-color-theme-ink);
  cursor: pointer;
  background-size: cover;
  width: 34.57px;
  min-width: 34.57px;
  height: 34.64px;
  margin: 13% 31%;
}
:host .rowLayout #sh-switch-org-default-org-char-div {
  margin: 0;
  max-width: 34.57px;
}
:host #sh-switch-org-default-org-name-div {
  font-size: 12.8px;
  line-height: 16.64px;
  margin: 0 7%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px;
  text-align: center;
  word-break: break-word;
}
:host .rowLayout #sh-switch-org-default-org-name-div {
  margin: 0;
  text-align: left;
  color: var(--prism-color-theme-neutral-pop-high);
}
:host #sh-switch-org-change-button {
  margin: 5% 14%;
  width: 72px !important;
  height: 22px !important;
  font-size: 11.8px !important;
  font-weight: 700 !important;
  line-height: 16.64px;
}
:host .rowLayout #sh-switch-org-change-button {
  margin: 0 0 0 auto !important;
  width: fit-content !important;
  color: var(--prism-color-theme-accent-primary);
}
:host #sh-switch-org-line-div {
  border-top: 1px solid var(--prism-color-theme-neutral-dim);
  margin: 1% 10%;
}
/*# sourceMappingURL=switch-organization-main.component.css.map */
