<sh-sidenav-layout
	*transloco="let t"
	[isCloseButtonEnabled]="false"
	[ngClass]="{
		'sh-shell-sidenav-background-color': (routeAccount$ | async) === true,
		'sh-shell-sidenav-color': (routeAccount$ | async) === false,
		'sh-shell-account': isAccount === true,
		'sh-shell-home': isAccount === false
	}"
>
	<sh-sidenav-layout-sidenav>
		<div *ngIf="!isMyAccount">
			<sh-switch-organization-main></sh-switch-organization-main>
			<mat-nav-list>
				<mat-list-item [routerLink]="['home']" routerLinkActive="mat-menu-item-active">
					<mat-icon aria-label="home" fontSet="prism-mat" fontIcon="home" id="sh-left-menu-home-icon">
					</mat-icon>
					<div class="mat-list-text" id="sh-left-menu-home">{{ t('cloud.shure-cloud.home') }}</div>
				</mat-list-item>
				<mat-list-item [routerLink]="['apps']" routerLinkActive="mat-menu-item-active">
					<mat-icon aria-label="apps" fontSet="prism-mat" fontIcon="apps" id="sh-left-menu-apps-icon">
					</mat-icon>
					<div class="mat-list-text" id="sh-left-menu-apps">{{ t('cloud.shure-cloud.apps') }}</div>
				</mat-list-item>
				<mat-list-item
					(click)="navigateToLicenses()"
					[class.mat-menu-item-active]="(toggleSecondaryNav$ | async) === toggleSecondayNavList.Licenses"
					*ngIf="defaultOrgId"
				>
					<img
						id="sh-left-menu-licenses-white"
						[src]="'../assets/images/sh-icon-license-white.svg' | version | async"
					/>
					<img
						id="sh-left-menu-licenses-black"
						[src]="'../assets/images/sh-icon-license-black.svg' | version | async"
					/>
					<span class="prism-md-sharp sh-icon-license-01"></span>
					<span class="mat-list-text" id="sh-left-menu-licenses">
						{{ t('cloud.shure-cloud.license-management.licenses') }}
					</span>
				</mat-list-item>
				<mat-list-item
					[routerLink]="['user-management']"
					routerLinkActive="mat-menu-item-active"
					*ngIf="defaultOrgId"
				>
					<mat-icon aria-label="users" fontSet="prism-mat" fontIcon="group" id="sh-left-menu-users-icon">
					</mat-icon>
					<span class="mat-list-text" id="sh-left-menu-users">
						{{ t('cloud.shure-cloud.users.user-management') }}
					</span>
				</mat-list-item>
				<mat-list-item
					[routerLink]="['event-log']"
					routerLinkActive="mat-menu-item-active"
					*ngIf="defaultOrgId && this.appEnv.featureFlags?.['event-log'] !== false"
				>
					<mat-icon
						aria-label="event-log"
						fontSet="prism-mat"
						fontIcon="sh-eventlog"
						id="sh-left-menu-event-icon"
					>
					</mat-icon>
					<span class="mat-list-text" id="sh-left-menu-event">
						{{ t('cloud.shure-cloud.event-log.event-log') }}
					</span>
				</mat-list-item>
				<mat-list-item
					[routerLink]="['organization-settings/details']"
					[class.mat-menu-item-active]="
						(toggleSecondaryNav$ | async) === toggleSecondayNavList.OrganizationSettings
					"
				>
					<mat-icon
						aria-label="organization-settings"
						fontSet="prism-mat"
						fontIcon="settings"
						id="sh-left-menu-organization-settings-icon"
					>
					</mat-icon>
					<span class="mat-list-text" id="sh-left-menu-organization-settings">
						{{ t('cloud.shure-cloud.org-settings.organization-settings') }}
					</span>
				</mat-list-item>
				<mat-list-item
					*ngIf="showNotifications"
					[routerLink]="['notifications-list']"
					routerLinkActive="mat-menu-item-active"
				>
					<mat-icon
						aria-label="notifications"
						fontSet="prism-mat"
						fontIcon="notifications"
						id="sh-left-menu-notifications-icon"
					>
					</mat-icon>
					<div class="mat-list-text" id="sh-left-menu-notifications">
						{{ t('cloud.shared.notification.title') }}
					</div>
				</mat-list-item>
				<mat-list-item
					*ngIf="showNotificationPreferences"
					[routerLink]="['notifications-preferences']"
					routerLinkActive="mat-menu-item-active"
				>
					<mat-icon
						aria-label="notification-preferences"
						fontSet="prism-mat"
						fontIcon="settings"
						id="sh-left-menu-notifications-preferences-icon"
					>
					</mat-icon>
					<div class="mat-list-text" id="sh-left-menu-notifications-preferences">
						{{ t('cloud.shared.notification-preferences.title') }}
					</div>
				</mat-list-item>
				<mat-list-item (click)="toggleSecnav()" *ngIf="(toggleSecondaryNav$ | async) !== ''">
					<div
						[ngClass]="{
							'sh-secondary-nav-inactive': (isSecOpen$ | async) === true,
							'sh-secondary-nav-active': (isSecOpen$ | async) === false
						}"
					>
						<mat-icon
							aria-label="toggle button"
							fontSet="prism-mat"
							fontIcon="arrow_forward_ios"
							id="sh-left-open-secondary-nav"
						></mat-icon>
						<span class="mat-list-text" id="sh-shell-secondary-nav-open">{{
							t('cloud.shure-cloud.license-management.open')
						}}</span>
					</div>
					<div
						[ngClass]="{
							'sh-secondary-nav-inactive': (isSecOpen$ | async) === false,
							'sh-secondary-nav-active': (isSecOpen$ | async) === true
						}"
					>
						<mat-icon
							aria-label="toggle button"
							fontSet="prism-mat"
							fontIcon="arrow_back_ios"
							id="sh-left-close-secondary-nav"
						></mat-icon>
						<span class="mat-list-text" id="sh-shell-secondary-nav-close">{{
							t('cloud.shure-cloud.license-management.close')
						}}</span>
					</div>
				</mat-list-item>
			</mat-nav-list>
		</div>
		<div *ngIf="isMyAccount">
			<sh-my-account-side-nav [navigateTo]="'home'"></sh-my-account-side-nav>
		</div>
		<!-- As per request from QA, added it as a hidden field until the display of version is finalized from UX. -->
		<div style="visibility: hidden" id="sh-ignite-app-version-div">{{ appVersion }}</div>
	</sh-sidenav-layout-sidenav>
	<sh-sidenav-layout-content>
		<div id="sh-shell-content-div">
			<div id="sh-shell-right-content-div">
				<sh-header-toolbar
					[ngClass]="{
						'sh-hide-header-toolbar': (getSecondaryNavListItems$ | async)?.length ?? 0 > 0
					}"
					[showSidenavToggleButton]="true"
					[showFeatureButton]="!isSmallDevice()"
					[showNotifications]="showNotifications"
					[showNotificationPreferences]="showNotificationPreferences"
					(toggleSidenav)="openSidenav()"
					(signOut)="onSignOut()"
				></sh-header-toolbar>
				<router-outlet></router-outlet>
			</div>
			<div id="sh-shell-footer-content-div" *ngIf="(getSecondaryNavListItems$ | async)?.length === 0">
				<sh-footer (contactUs)="onContactUs()"></sh-footer>
			</div>
		</div>
	</sh-sidenav-layout-content>
</sh-sidenav-layout>
<div id="sh-shell-maintenance-banner-div" *ngIf="maintenanceData && showMaintenanceBanner">
	<div id="sh-shell-maintenance-message-div" *transloco="let t">
		<p class="sh-shell-maintenance-info" id="sh-shell-maintenance-text-p">
			{{ t('cloud.shared.maintenance.maintenance-banner-text', { startDate: startDate, endDate: endDate }) }}
		</p>

		<div class="sh-shell-maintenance-info" id="sh-shell-maintenance-message-div">
			{{ maintenanceData.message }}
		</div>
	</div>

	<mat-icon
		class="prism-md-sharp close close-icon sh-shell-maintenance-info"
		id="sh-shell-close-icon"
		fontSet="prism-mat"
		fontIcon="close"
		(click)="showMaintenanceBanner = false"
	></mat-icon>
</div>
