<div id="sh-change-organization-dialog-container-div" *transloco="let t; read: 'cloud.shared.switch-org'">
	<ng-container>
		<h1 id="sh-change-organization-title-h1" mat-dialog-title>
			{{ t('change-organization') }}
		</h1>
		<mat-dialog-content id="sh-change-organization-content-div">
			<div id="sh-change-organization-title-div">
				{{ t('select-organization-from-list') }}
			</div>
			<div id="sh-change-organization-search-container-div">
				<div id="sh-change-organization-search-bar-div">
					<sh-search-bar
						[queryString]="''"
						(querySubmitted)="applyFilter($event)"
						(queryChanged)="applyFilter($event)"
					></sh-search-bar>
				</div>
				<prism-button
					variant="bright"
					id="sh-change-organization-create-button-div"
					(click)="openCreateOrganization()"
				>
					+ {{ t('create-organization') }}
				</prism-button>
			</div>
			@if (toggleLoader() === false) {
				@if (organizationsList && organizationsList.length > 0) {
					<mat-list class="sh-change-organization-organizations-list" id="sh-change-organization-list">
						<mat-list-item
							id="sh-change-organization-organization-item-{{ org.id }}"
							class="sh-change-organization-organization-item"
							*ngFor="let org of organizationsList"
							(click)="selectOrganization($event, org)"
							[class.sh-change-organization-selected]="
								selectedOrganization && selectedOrganization.id === org.id
							"
						>
							<div
								class="sh-change-organization-details-div"
								id="sh-change-organization-details-div-{{ org.id }}"
							>
								<div
									class="sh-change-organization-icon-container-div"
									id="sh-change-organization-icon-container-div-{{ org.id }}"
									[style.background-color]="org.profileColor"
								>
									<div
										class="sh-change-organization-org-icon-div"
										id="sh-change-organization-org-icon-div-{{ org.id }}"
									>
										{{ org.initials }}
									</div>
								</div>
								<div
									class="sh-change-organization-organization-name-div"
									id="sh-change-organization-organization-name-div-{{ org.id }}"
								>
									<h4
										id="sh-change-organization-organization-name-h4-{{ org.id }}"
										class="sh-change-organization-organization-name-h4"
									>
										{{ org.name }}
										@if (defaultOrgId === org.id) {
											({{ t('current') }})
										}
									</h4>
									<p
										class="sh-change-organization-company-name-p"
										id="sh-change-organization-company-name-p-{{ org.id }}"
									>
										{{ org.companyName }}
									</p>
								</div>
							</div>
						</mat-list-item>
					</mat-list>
				} @else {
					<div class="sh-change-organization-organizations-list" id="sh-change-organization-no-rows-div">
						<p id="sh-change-organization-no-organizations-p">
							{{ t('no-organizations-found') }}
						</p>
						<p id="sh-change-organization-different-search-text-p">
							{{ t('try-different-search') }}
						</p>
					</div>
				}
			}
			@if (toggleLoader()) {
				<div id="sh-change-organization-loader-div">
					<sh-loader></sh-loader>
				</div>
			}
			<div id="sh-change-organization-action-buttons-div">
				<button mat-button color="accent" id="sh-change-organization-cancel-button" (click)="closeDialog()">
					{{ 'cloud.shared.button-text.cancel' | transloco }}
				</button>
				<button
					mat-button
					[disabled]="!selectedOrganization || !selectedOrganization.id"
					color="accent"
					id="sh-change-organization-create-org-button"
					(click)="changeOrganization()"
				>
					{{ t('change-organization') }}
				</button>
			</div>
		</mat-dialog-content>
	</ng-container>
</div>
