import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';
import {
	DeviceBehaviorPlugin,
	DeviceImage,
	DeviceImageBehaviorPlugin
} from '@shure/shared/angular/device-behavior/core';

/**
 * `Mxa920DeviceBehaviorPluginBase` provides an base for common feature description across applications,
 * fx. designer, cui etc.
 *
 * **Do not put app specifics into this class. If app specific setup is required create a
 * plugin for the app.**
 */
export class Mxa920DeviceBehaviorPlugin implements DeviceBehaviorPlugin, DeviceImageBehaviorPlugin {
	public readonly models: DeviceModel[] = [DeviceModel.MXA920R, DeviceModel.MXA920S];

	public getImage(model: DeviceModel): DeviceImage | undefined {
		switch (model) {
			case DeviceModel.MXA920R:
				return {
					lightsBackground: 'device-mxa920-r.svg',
					live: 'device-mxa920-r.svg',
					virtual: 'device-mxa920-r-line.svg',
					liveBack: 'device-mxa920-r-back.svg',
					virtualBack: 'device-mxa920-r-back-line.svg'
				};
			case DeviceModel.MXA920S:
				return {
					lightsBackground: 'device-mxa920-s.svg',
					live: 'device-ceiling-array.svg',
					virtual: 'device-mxa910-line.svg',
					liveBack: 'device-mxa920-s-back.svg',
					virtualBack: 'device-mxa920-s-back-line.svg'
				};
			default:
				return undefined;
		}
	}
}
