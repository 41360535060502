/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { setAssetPath } from '@shure/prism-web-components/dist/components';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { setAgGridLicense } from '../../../../ag-grid.config';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
	// Disable console logging in production
	if (window) {
		window.console.log = () => {};
		window.console.warn = () => {};
		window.console.debug = () => {};
		window.console.info = () => {};
	}
}

// Make Prism icon components available
// See http://shure-prism-web-components.dev-aws-pr-ms-influx.shure.com/?path=/docs/documentation-how-to-use--docs#usage-of-prism-icons
setAssetPath(window.location.origin);

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	// eslint-disable-next-line no-console
	.catch((err) => console.error(err));

setAgGridLicense();
